<template>
    <section>
        <h2 class="mb-5">Elenco Agenti</h2>
        <div class="text-right mb-3">
            <b-button href="/backend/backend_agent_details" variant="primary">Nuovo agente</b-button>
        </div>

        <vue-good-table :columns="columns" :rows="agent_list">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'action'" class="text-nowrap">
                    <b-button variant="primary" @click="$router.push('/backend/backend_agent_details/' + props.row.id_agent)">Dettaglio
                        Account</b-button>

                </span>
            </template>
            <div slot="emptystate">
                Nessun agente presente
            </div>
        </vue-good-table>
    </section>
</template>

<script>
import { Requests } from "@/api/requests.js";
import { BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
});

export default {
    components: {
        VueGoodTable,
        BButton,
    },

    data: () => ({

        agent_list: [],
        columns: [
            {
                label: "ID",
                field: "id_agent",
            },
            {
                label: "Nome Agente",
                field: "agent_first_name",
            },
            {
                label: "Cognome Agente",
                field: "agent_last_name",
            },
            {
                label: "Data Creazione",
                field: "created_at",
                formatFn: (val) => dateFormatter.format(new Date(val)),
            },
            {
                label: "Azioni",
                field: "action",
            },
        ],
    }),

    created() {
        this.getAgentList();
    },

    methods: {
        async getAgentList() {
            try {
                const response = await Requests.getAgentList();
                this.agent_list = response.data;
                console.log(this.agent_list);
            } catch {
                console.log(err);
            }
        },
    },
};
</script>